.tile {
    min-height: 90vh;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: row;

    flex-wrap: wrap;
}

.first-tile {
    height: calc(100vh - 80px);

    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.vertical {
    flex-direction: column;   
}

.main-background {
    background-image: url('../../public/images/main-background.webp');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.dj {
    background-image: url('../../public/images/dj-fred-background.webp');
}

.fotograf {
    background-image: url('../../public/images/photographer_background_g.webp');
}

.dekoracje {
    background-image: url('../../public/images/decoration_background_dark.webp');
}

.samochody {
    background-image: url('../../public/images/wolga4.webp');
}


.title {
    width: 70%;
    min-height: 75%;

    font-size: 6vw;
    color: white;

    text-shadow: 7px 7px 10px #0303036e;
}

.title img {
    width: 50%;
}

.title p {
    margin: 3vh;
}

.subtitle {
    font-size: 4vw;
}

.pricingContainer {
    width: 100%;
    min-height: 80vh;
    overflow: hidden;
}

.pricingContainer h1 {
    text-align: center;
    font-size: 75px;
    width: 100%;
}

.pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pricingColumn {
    display: block;
    width: 400px;   
    border: 1px solid #520f85;
    box-shadow: 0px 10px 20px #0303036e;
    border-radius: 7px;
    margin: 60px;
    user-select: none;
}

.pricingHeader {
    font-size: 60px;
    margin: 0;
}

.pricingRow {
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
}

.order {
    width: 100%;
    height: 70px;
    border: none;
    border-radius: 0 0 7px 7px;
    background: linear-gradient(90deg,#310c4d, #520f85);
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.simple-description {
    font-size: 26pt;    
}

@media screen and (max-width: 1200px) {
    .polaroidWidget, .letterWidget {
        flex-direction: column !important;
    }

    .description {
        margin-top: 30px !important;
        margin-bottom: 100px !important;
        width: 90% !important;
        font-size: 12pt !important;
    }

    .simple-description {
        font-size: 20pt;
        text-align: left;
    }

    .letter {
        width: 100%;
        margin-bottom: 100px;
    }

    .polaroid {
        margin: auto;
    }

}

@media screen and (max-width: 500px) {
    .pricingColumn {
        width: 350px;
        margin: 30px;
        margin-left: 0;
        margin-right: 0;
    }

    .pricingRow  {
        width: 350px;
    }

    .pricingHeader {
        font-size: 40px;
    }

    .title {
        width: 100%;
    }

    .subtitle {
        font-size: 17pt;
    }
        
    .title img {
        width: 70%;
    }


}