.gallery {
    width: 70%;
    height: 70vh;

    display: grid;
    grid-template-columns: 10% auto 10%;
}

.arrow_container {
    display: flex;
    justify-content: center;
    align-items: center;   
}

.arrow {
    width: 100%;
    cursor: pointer;
}

.gallery_view {
    height: 70vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery_image {
    max-width: 100%;
    object-fit: cover;
}