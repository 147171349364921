header {
    background: linear-gradient(90deg,#310c4d, #520f85);
    height: 80px;
    
    display: flex;
    flex-direction: row;
}

.outlet {
    min-height: calc(100vh - 80px);
}


img {
    max-height: 100%;
}

.menu-banner {
    height: 100%;
    box-sizing: border-box;
    padding-left: 10px;
}

/* .menu-banner, .menu-options, .menu-item {
    border: 1px solid red;
} */

.menu-banner {
    padding-top: 7px;
    padding-bottom: 7px;
}

.menu-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    box-sizing: border-box;
}

.menu-item {
    width:  12vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item:hover {
    background-color: #310c4d;
    text-decoration: underline;
}

.menu-options a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
}

@media screen and (max-width: 800px) {
    header {
        height: 140px;
        flex-direction: column;
    }

    .outlet {
        min-height: calc(100vh - 140px);
    }

    .menu-banner {
        width: 100%;
        text-align: center;
        height: 80px;
    }

    .menu-banner > img {
        width: 33vw;
    }

    .menu-options {
        justify-content: space-evenly;
    }

    .menu-item {
        width: 12vw;
        height: 60px;
        font-size: 10pt;
    }

    .first-tile {
        height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 420px) {
    .menu-item {
        font-size: 8pt;
    }
}