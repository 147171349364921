@keyframes price-option {
    0% {
        transform: scale(1);
    }
    
    100% {
        transform: scale(1.05);
    }
}

@keyframes price-option-off {
    0% {
        transform: scale(1.05);
    }
    
    100% {
        transform: scale(1);
    }
}

@keyframes logo-fadein {
    0% {
        transform: translate(0px, 50px);
        opacity: 0;
    }

    100% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
}

.pricingColumn:hover {
    animation: price-option 0.5s;
    transform: scale(1.05);
}

.pricingColumn {
    animation: price-option-off 0.5s;
    transform: scale(1);
}

.title {
    animation: logo-fadein 1s;
}