html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Mulish', sans-serif;
}

.notDraggable, img { 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

footer {
    height: 240px;
    padding-top: 3px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: right;
    color: #707070;
    background-color: #111012;
}