.polaroidWidget, .letterWidget   {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.hw-text {
    width: 50%;
}

.hw-photo {
    height: 50vh;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;
}

.hw-text {
    margin-left: 120px;
    margin-right: 120px;
    font-size: 20pt;
    text-align: justify;
}

.hw-photo > img {
    max-width: 100%;
    max-height: 100%;
}

.hw-wide {
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hw-wide > img {
    max-width:  100%;
    max-height: 100%;
}

.wide-text {
    width: 40vw;
    margin-left: 60px;
    margin-right: 60px;
    font-size: 20pt;
    text-align: justify;
    /* background-color: pink; */
}

.polaroid {
    transform: rotate(-12deg);

    box-shadow: 7px 7px 20px #696969;
    width: 400px;
    height: 500px;

    padding-top: 10px;
}

.description {
    width: 50%;
    font-size: 20pt;
    padding: 20px;
    line-height: 45px;
    text-align: justify;
}

.description > h1 {
    line-height: 70px;
    text-align: left;
}

.hw-text > h1 {
    line-height: 70px;
    text-align: left;
}

.printed {
    margin: 10px;
    height: 85%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.printed2 {

    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.p1 {
    background-image: url('../../public/content/IMG_0282.webp');
}

.p2 {
    background-image: url('../../public/content/gallery_1.webp');
}

.letter {
    transform: rotate(-12deg);

    box-shadow: 7px 7px 20px #696969;
    width: 400px;
    height: 600px;

    margin: auto;
}

.inverted {
    transform: rotate(12deg);
}

@media screen and (max-width: 1200px) {
    .hw-text {
        margin-top: 50px;
    }

    .wide-text, .hw-wide > img {
        width: 60vw;
        margin: 30px;
    }

    .hw-wide {
        width: 90%;
    }
}

@media screen and (max-width: 750px) {
    .hw-text, .wide-text {
        width: 90%;
        margin-left: 0;
        margin-right: 0;
        text-align: justify;
    }

    .wide-text, .hw-wide > img {
        width: 90%;
        margin: 30px;
    }
}

@media screen and (max-width: 530px) {
    .letter {
        transform: rotate(-12deg) scale(0.7);
    }

    .inverted {
        transform: rotate(12deg) scale(0.7);
    }
    
    .polaroid {
        transform: rotate(-12deg) scale(0.7);
    }

    .hw-wide {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wide-text, .hw-wide > img {
        width: 90%;
        margin: 30px;
    }
    
}

@media screen and (max-width: 450px) {
    .hw-text, .wide-text {
        font-size: 15pt;
    }
}

.pricingImage {
    max-width: 100%;
}