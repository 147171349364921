.window {
    position: fixed;
    left: calc(50% - 500px/2);
    top: calc(50% - 300px);
    z-index: 100;
    background-color: #fff;
    height: 400px;
    width: 500px;
    padding: 10px;
    text-align: center;
    border: 1px solid #520f85;
    box-shadow: 0px 10px 20px #0303036e;
    border-radius: 7px;
}

.btn, .select-plan>button {
    width: 100%;
    height: 80px;
    color: #fff;
    border: none;
    font-size: 18pt;
    background: linear-gradient(90deg,#310c4d, #520f85);
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
}

