/* shapes */

.triangle-bottomleft {
    width: 0;
    height: 0;
    border-bottom: 100px solid rgb(123, 80, 186);
    border-right: 100vw solid transparent;
}

.triangle-bottomright {
    width: 0;
    height: 0;
    border-bottom: 100px solid rgb(123, 80, 186);
    border-left: 100vw solid transparent;
}

.triangle-topright {
    width: 0;
    height: 0;
    border-top: 100px solid rgb(113, 70, 176);
    border-left: 100vw solid transparent;
}

.triangle-topleft {
    width: 0;
    height: 0;
    border-top: 100px solid rgb(113, 70, 176);
    border-right: 100vw solid transparent;
}

.bottomdark {
    border-bottom-color:rgb(27, 26, 28) !important;
}